.eyecatcher {
    &.large {
        .eyecatcher-container {
            max-width: none;
            .container-holder {
                h1 {
                    position: absolute;
                    right: 20px;
                    bottom: 200px;
                    text-align: right;
                    z-index: 2;
                    margin-bottom: 0;
                    color: $white;
                    line-height: 1;
                    font-size: 36px;
                    font-weight: $font-weight-bold;
                    max-width: 730px;
                    @include media-breakpoint-up(xl) {
                        font-size: 80px;
                        bottom: 20px;
                    }
                }
                .owl-carousel {
                    .owl-stage-outer {
                        .owl-stage {
                            .owl-item {
                                .item {
                                    position: relative;

                                    &::after {
                                        content: "";
                                        position: absolute;
                                        background: linear-gradient(180deg, rgba($body-color, 0), $body-color);
                                        bottom: 0;
                                        left: 0;
                                        height: 50%;
                                        width: 100%;
                                        opacity: 0.5;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .eyecatcher {
        height: 50vh;
        min-height: 450px;
        max-height: 540px;
        .owl-carousel {
            min-height: unset;
            .item {
                height: 50vh !important;
                min-height: 450px;
                max-height: 540px;
            }
        }
        &.large {
            height: 100vh;
            min-height: 500px;
            max-height: 1080px;
            .owl-carousel {
                min-height: unset;
                .item {
                    height: 100vh !important;
                    min-height: 450px;
                    max-height: 1080px;
                }
            }
        }
    }
}