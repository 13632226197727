.home-blocks {
	.grid {
		&:first-of-type {
			.item {
				&:nth-of-type(2),
				&:nth-of-type(4) {
					.card {
						&-image {
							@include media-breakpoint-up(xl) {
								order: 2;
							}
						}

						&-body {
							@include media-breakpoint-up(xl) {
								order: 1;
							}
						}
					}
				}

				.card {
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					border: 0;
					border-radius: 0;

					&-image {
						@include media-breakpoint-up(xl) {
							@include make-col(6);
						}

						.card-image-link {
							height: 100%;

							.card-img {
								height: 200px;
								object-fit: cover;
								width: 100vw;
								@include media-breakpoint-up(xl) {
									height: 100%;
									width: 100%;
								}
							}
						}
					}

					&-body {
						@include media-breakpoint-up(xl) {
							@include make-col(6);
						}
						background: $blue;
						padding: 35px 15px 25px 25px;

						.card-caption {
							.card-title-link {
								color: $white;

								.card-title {
									font-size: $font-size-base * 1.875;
								}
							}

							.card-description {
								color: $base-1;

								&-content {
									font-size: $h4-font-size;
									display: -webkit-box;
									-webkit-line-clamp: 3;
									-webkit-box-orient: vertical;
									overflow: hidden;
									text-overflow: ellipsis;
								}

								.list {
									&-item {
										.icon {
											i {
												font-weight: 600;
											}
										}
									}
								}
							}
						}

						.card-buttons {
							z-index: 1;

							.card-btn {
								@extend .btn-primary;
							}
						}
					}
				}
			}
		}
	}
}

.slider {
	.owl-stage-outer {
		.owl-stage {
			.owl-item {
				.item {
					padding-bottom: 15px;

					.card {
						box-shadow: 3px 3px 10px rgba($black, 0.3);
						border: 0;
						border-radius: 0;

						&-body {
							padding: 35px 15px;

							.card-caption {
								.card-title-link {
									.card-title {
										font-size: $font-size-base * 1.5;
										font-weight: $font-weight-bold;
										text-align: left;
									}
								}

								.card-description {
									.list {
										display: flex;

										&-item {
											display: flex;
											align-items: center;

											.label {
												font-family: $font-great-vibes;
												font-size: $font-size-base * 1.5;
											}

											&:first-of-type {
												margin-right: 30px;

												&::after {
													content: "-";
													position: absolute;
													right: -15px;
													top: 5px;
												}
											}
										}
									}
								}
							}

							.card-buttons {
								margin-top: 60px;

								.card-btn {
									@extend .btn-secondary;
								}
							}
						}
					}
				}
			}
		}
	}
}

.card {
	.card-caption {
		.card-description {
			min-height: 100% !important;
			max-height: 100% !important;
			pointer-events: none;

			&::after {
				content: none;
			}
		}
	}
}

.content.overview, .content.detail {
	.grid {
		.item {
			margin: 0 0 50px 0;
			@include media-breakpoint-up(lg) {
				@include make-col(4);
			}

			.card {
				box-shadow: 3px 3px 10px rgba($black, 0.3);
				border: 0;
				border-radius: 0;

				&-body {
					padding: 35px 15px;

					.card-caption {
						.card-title-link {
							.card-title {
								font-size: $font-size-base * 1.5;
								font-weight: $font-weight-bold;
								text-align: left;
							}
						}

						.card-description {
							.list {
								display: flex;

								&-item {
									display: flex;
									align-items: center;

									.label {
										font-family: $font-great-vibes;
										font-size: $font-size-base * 1.5;
									}

									&:first-of-type {
										margin-right: 30px;

										&::after {
											content: "-";
											position: absolute;
											right: -15px;
											top: 5px;
										}
									}
								}
							}
						}
					}

					.card-buttons {
						margin-top: 60px;

						.card-btn {
							@extend .btn-secondary;
						}
					}
				}
			}
		}
	}
}

.content.detail {
	.grid {
		.item {
			.card {
				&-body {
					.card-caption {
						.card-description {
							.list {
								display: block !important;

								&-item {
									.icon {
										i {
											font-weight: $font-weight-bold;
										}
									}

									&::after {
										content: none !important;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
