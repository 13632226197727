.btn {
    display: inline-flex;
    align-items: center;
    padding: 5px 30px !important;
    font-size: $font-size-base !important;
    font-weight: $font-weight-bold !important;
    border-radius: 4px;
    color: $white;
    text-decoration: none;
    transition: 0.3s;
 
    &-primary {
        @extend .btn;
        color: $white;
        background: $primary;
        
        &:hover {
            color: $primary;
            background: $white;
            border-color: $primary;
        }
    }
    &-secondary {
        @extend .btn;
        color: $secondary;
        background: transparent;
        border-color: $secondary;
        
        &:hover {
            color: $white;
            background: $secondary;
            border-color: $secondary;
        }
    }
    &-outline-primary {
        @extend .btn;
        color: $primary;
        border-color: $primary;
        background: transparent;

        &:hover {
            background: $primary;
            color: $white;
        }
    }
    &-outline-secondary {
        @extend .btn;
        color: $secondary;
        border-color: $secondary;
        background: transparent;

        &:hover {
            background: $secondary;
            border-color: $secondary;
            color: $white;
        }
    }
}
