/* ==========================================================================
   body
   ========================================================================== */
   $body-padding: 0 !default;
   $body-sticky-padding: 0 !default;

   /* ==========================================================================
   color
   ========================================================================== */
   $base-1: #C7DEF0;
   $base-2: #e6dacc;
   $base-3: #0590D0;
   $base-4: #0491D0;
   $base-5: #EDEDF4;
   $base-6: #707070;
   $box-shadow: rgba($black , 0.16);
