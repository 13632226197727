.slider {
	.owl-stage-outer {
		.owl-stage {
			.owl-item {
				.item {
					padding-bottom: 15px;

					.card {
						box-shadow: 3px 3px 10px rgba($black, 0.3);
						border: 0;
						border-radius: 0;

						&-body {
							padding: 35px 15px;

							.card-caption {
								.card-title-link {
									.card-title {
										font-size: $font-size-base * 1.5;
										font-weight: $font-weight-bold;
										text-align: left;
									}
								}

								.card-description {
									.list {
										display: flex;

										&-item {
											display: flex;
											align-items: center;

											.label {
												font-family: $font-great-vibes;
												font-size: $font-size-base * 1.5;
											}

											&:first-of-type {
												margin-right: 30px;

												&::after {
													content: "-";
													position: absolute;
													right: -15px;
													top: 5px;
												}
											}
										}
									}
								}
							}

							.card-buttons {
								margin-top: 60px;

								.card-btn {
									@extend .btn-secondary;
								}
							}
						}
					}
				}
			}
		}
	}
}

.card {
	.card-caption {
		.card-description {
			min-height: 100% !important;
			max-height: 100% !important;
			pointer-events: none;

			&::after {
				content: none;
			}
		}
	}
}

.content.overview, .content.detail {
	.grid {
		.item {
			margin: 0 0 50px 0;
			@include media-breakpoint-up(lg) {
				@include make-col(4);
			}

			.card {
				box-shadow: 3px 3px 10px rgba($black, 0.3);
				border: 0;
				border-radius: 0;

				&-body {
					padding: 35px 15px;

					.card-caption {
						.card-title-link {
							.card-title {
								font-size: $font-size-base * 1.5;
								font-weight: $font-weight-bold;
								text-align: left;
							}
						}

						.card-description {
							.list {
								display: flex;

								&-item {
									display: flex;
									align-items: center;

									.label {
										font-family: $font-great-vibes;
										font-size: $font-size-base * 1.5;
									}

									&:first-of-type {
										margin-right: 30px;

										&::after {
											content: "-";
											position: absolute;
											right: -15px;
											top: 5px;
										}
									}
								}
							}
						}
					}

					.card-buttons {
						margin-top: 60px;

						.card-btn {
							@extend .btn-secondary;
						}
					}
				}
			}
		}
	}
}

.content.detail {
	.grid {
		.item {
			.card {
				&-body {
					.card-caption {
						.card-description {
							.list {
								display: block !important;

								&-item {
									.icon {
										i {
											font-weight: $font-weight-bold;
										}
									}

									&::after {
										content: none !important;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
