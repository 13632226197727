.header {
	position: sticky;
	@include media-breakpoint-up(xl) {
		&.sticky {
			position: sticky;
		}
	}

	&-socket {
		display: none;
	}

	&-main {
		background: $blue !important;

		.container-navbar {
			max-width: none;

			.container-holder {
				.navbar {
					padding: 0 15px;
					@include media-breakpoint-up(xl) {
						padding: 0 40px;
					}

					.logo {
						width: 220px;
						height: 55px;
						max-width: none;
						display: flex;
						align-items: center;
						justify-content: center;
						padding: 0;
						background: $white;
						@include media-breakpoint-down(lg) {
							order: 2;
							margin: 0 auto -15px 0;
							box-shadow: 3px 3px 10px rgba($black, 0.15);
							img {
								max-width: 140px;
							}
						}
						@include media-breakpoint-up(xl) {
							width: 350px;
							height: 90px;
						}
					}

					&-collapse {
						@include media-breakpoint-down(lg) {
							order: 4;
							margin-top: 30px;
						}

						.navbar-nav {
							.nav-item {
								.nav-link {
									color: $white;
									font-weight: $font-weight-bold;
									font-size: $font-size-base / 1.067;
									padding-right: 24px;

									&:hover {
										opacity: 0.7;
									}
								}

								.dropdown {
									&-toggle {
										position: relative;

										&::after {
											content: "\f078";
											position: absolute;
											right: 10px;
											display: block;
											font-family: $font-awesome;
											font-weight: 900;
											transform: translateY(-50%);
											top: 50%;
											font-size: $font-size-base_xxs;
											border: none !important;
										}

										@include media-breakpoint-down(lg) {
											&[aria-expanded="true"] {
												&::after {
													content: "\f068";
												}
											}
											&::after {
												margin-right: 15px;
												content: "\F067";
												font-family: $font-awesome;
												font-size: $font-size-base_xs;
												font-weight: 700;
												border: none;
											}
										}
									}

									&-menu {
										@include media-breakpoint-down(lg) {
											position: static !important;
											transform: none !important;
											min-width: 100%;
											border: none;
											background-color: transparent;
											box-shadow: none;
											padding: 0;
											margin: 0;
										}
										@include media-breakpoint-up(xl) {
											left: -10%;
											border-radius: 6px;
											box-shadow: 0px 3px 6px $box-shadow;

											&:before,
											&:after {
												content: "";
												display: block;
												height: 0;
												left: 50%;
												position: absolute;
												width: 0;
											}
											&:before {
												border-left: 8px solid transparent;
												border-right: 8px solid transparent;
												border-bottom: 6px solid $base-5;
												margin-left: -5px;
												top: -6px;
												z-index: 4;
											}
											&:after {
												border-left: 6px solid transparent;
												border-right: 6px solid transparent;
												border-bottom: 5px solid $white;
												margin-left: -3px;
												top: -5px;
												z-index: 5;
											}
										}

										.nav-item {
											.nav-link {
												color: $white;
												font-size: $font-size-base_sm;
												@include media-breakpoint-up(xl) {
													color: $blue;
												}

												&:hover {
													background: $blue;
													color: $white;
													opacity: 1;
												}
											}

											a.highlight {
												background: yellow;
											}
										}
									}
								}
							}
						}
					}

					&-toggler {
						width: 40px;
						height: 40px;
						border: none;
						margin: 0 0 0 -15px;
						@include media-breakpoint-down(lg) {
							order: 1;
						}

						&-icon {
							color: white;
						}
					}

					.search_book_button {
						margin-bottom: 0;
						@include media-breakpoint-down(lg) {
							display: none !important;
						}

						li {
							a {
								@extend .btn-primary;
							}
						}
					}

					.site_switcher {
						@include media-breakpoint-down(xs) {
							position: absolute;
							top: 10px;
							right: 15px;
						}
						@include media-breakpoint-down(lg) {
							order: 3;
						}

						.navbar-nav {
							flex-wrap: nowrap !important;
							flex-direction: row;

							.nav-item {
								width: 21px;
								height: 21px;
								margin: 0 0 0 10px;
								overflow: hidden;
								border-radius: 100%;

								.nav-link {
									font-size: 1.125rem;
									line-height: 1;
									display: block;
									padding: 0;

									img {
										max-width: 100%;
										transform: scale(1.6);
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
