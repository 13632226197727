.footer {
    &-top {
        background-color: white !important;
        padding: 120px 0 20px !important;
        .container-default {
            .container-holder {
                display: flex;
                justify-content: center;
            }
        }
    }
    &-middle {
        background: $secondary;
        padding: 10px 0 70px !important;
        text-align: center;
        .container-default {
            .container-holder {
                p,
                h3 {
                    color: $white;
                }
                ul {
                    display: flex;
                    justify-content: center;
                    flex-wrap: wrap;
                    padding-top: 30px;
                    li {
                        margin-bottom: 25px;
                        a {
                            color: $white;
                            position: relative;
                            padding-left: 30px;
                            margin: 0 0 30px 0;
                            font-weight: 700;
                            cursor: pointer;
                            text-decoration: none;
                            @include media-breakpoint-up(sm) {
                                margin: 0 30px 30px 30px;
                            }
                            &:hover {
                                &::before {
                                    background: $white;
                                    color: $primary;
                                }
                            }
                            &::before {
                                position: absolute;
                                left: -25px;
                                top: -10px;
                                font-size: $font-size-base !important;
                                font-family: $font-awesome;
                                font-weight: 400;
                                background: $primary;
                                width: 40px;
                                height: 40px;
                                border-radius: 50%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                cursor: pointer;
                            }
                        }
                        .telephone-number {
                            &::before {
                                content: "\F095";
                            }
                        }
                        .email {
                            &::before {
                                content: "\F0E0";
                            }
                        }
                    }
                }
                h3 {
                    font-size: $font-size-base * 1.625;
                    font-weight: $font-weight-base;
                    margin-top: 100px;
                }
            }
        }
    }
    &-socket {
        background: $white !important;
        padding: 20px 0 !important;
        .container-navbar {
            display: flex;
            align-items: center;
            justify-content: center;
            .container-holder {
                .navbar {
                    .page_block {
                        .list {
                            display: flex !important;
                            &-item {
                                .link {
                                    color: $blue;
                                    font-size: $font-size-base_sm;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
