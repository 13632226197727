// mini-sab
.mini-sab {
	padding: 0; // override default
	background: none; // override default
	.container-default {
		padding: 0;

		.container-holder {
			background: $secondary; // override default
			@include media-breakpoint-down(lg) {
				z-index: 100;
				position: relative;
				width: 220px;
				margin: -180px 0 0 40px;
				padding: 15px;
			}
			@include media-breakpoint-down(sm) {
				margin: -110px 0 0 40px;
			}
			@include media-breakpoint-up(xl) {
				z-index: 100;
				position: absolute;
				top: 90px;
				left: 40px;
				width: 350px;
				padding: 30px;
				border-top: 90px solid $white;
				box-shadow: 3px 3px 10px rgba($black, 0.15);
			}

			.heading {
				color: $white;
				font-size: 30px;
				@include media-breakpoint-up(xl) {
					font-size: 40px;
				}
				text-align: center;
				font-family: $font-great-vibes;
			}
		}
	}
}
