/* bootstrap > theme-colors
   ========================================================================== */
   $primary: #0590D0 !default;
   $secondary: #0A2443 !default;
   $blue: #003069;
   $body-color: $secondary !default;
   
   /* bootstrap > font
          ========================================================================== */
   @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;700;800&display=swap');
   @import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');

   $font-mulish: 'Mulish', sans-serif;
   $font-great-vibes: 'Great Vibes', cursive;
   $font-awesome: "Font Awesome 5 Pro";
   $font-family-base: $font-mulish !default;
   
   $font-weight-light: 300;
   $font-weight-base: 400;
   $font-weight-bold: 800;
   
   $font-size-base: 1rem !default; //16px
   $font-size-base_sm: 0.875rem !default; //14px
   $font-size-base_xs: 0.813rem !default; //13px
   $font-size-base_xxs: 0.75rem !default; //12px
   
   $h1-font-size: $font-size-base * 3.75 !default;
   $h2-font-size: $font-size-base * 3.75 !default;
   $h3-font-size: $font-size-base * 1.5 !default;
   $h4-font-size: $font-size-base * 1.125 !default;
   $h5-font-size: $font-size-base_sm !default;
   $h6-font-size: $font-size-base_xs !default;
   
   body.default {
      overflow-x: hidden;
   }
   