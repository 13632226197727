.content.overview {
    background-color: $base-2;
    padding: 85px 0;
    .container-default {
        @include media-breakpoint-up(lg) {
            max-width: 1140px;
        }
        .container-holder {
            .blog_filter {
                display: none;
            }
        }
    }
}
.content.detail {
    background-color: $base-2;
    padding: 50px 0 90px;
    .info {
        .btn-back {
            font-weight: $font-weight-bold;
            text-decoration: none;
            &::before {
                content: "\F053" !important;
            }
        }
        .accommodation-properties {
            &__item {
                .accommodation-properties__icon {
                    i {
                        font-weight: 600;
                    }
                }
            }
        }
        .accommodation-properties + h1 {
            text-transform: capitalize;
        }
        h2 {
            font-family: $font-great-vibes;
            font-weight: $font-weight-base;
        }
        h4 {
            margin-bottom: 15px;
        }
        h4 + p {
            margin-bottom: 30px;
        }
        h3 +  ul {
            list-style-type: none;
            padding-left: 22px;
            padding-bottom: 10px;
            border-bottom: 2px solid rgba($secondary, 0.5);
            li {
                position: relative;
                margin-bottom: 10px;
                &::before {
                    content: "\f111";
                    position: absolute;
                    left: -20px;
                    top: 5px;
                    font-family: $font-awesome;
                    font-size: .5rem;
                    font-weight: $font-weight-bold;
                }
            }
        }
    }
}