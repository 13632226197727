.main {
	.intro.content {
		padding: 95px 0 15px;
		@include media-breakpoint-up(sm) {
			padding: 95px 0 85px;
		}

		.container-default {
			.container-holder {
				max-width: 730px;
				margin: auto;
				text-align: center;

				h1 {
					font-family: $font-great-vibes;
					font-weight: $font-weight-base;
				}

				p {
					font-size: $font-size-base * 1.25;
					@include media-breakpoint-up(sm) {
						font-size: $h3-font-size;
					}
				}
			}
		}
	}

	.home-blocks {
		padding-bottom: 0;

		.container-default {
			.container-holder {
				.grid {
					&:first-of-type {
						margin-bottom: 35px;
						@include media-breakpoint-up(sm) {
							margin-bottom: 95px;
						}

						.item {
							@include media-breakpoint-up(sm) {
								@include make-col(6);
							}
						}
					}

					&:last-of-type {
						.item {
							.card {
								border: 0;

								&-image {
									font-size: 0;
								}

								&-body {
									.card-caption {
										.card-title {
											font-size: $h4-font-size;
											line-height: 1.2;
											font-weight: $font-weight-base;
											text-align: center;
										}
									}
								}
							}
						}
					}

					.card-subtitle {
						color: #fff;
					}
				}
			}
		}
	}

	.accomodation {
		background-color: $base-2;
		padding: 95px 0;

		.container-default {
			.container-holder {
				h2 {
					font-family: $font-great-vibes;
					font-size: $font-size-base * 2.25;
					@include media-breakpoint-up(sm) {
						font-size: $h1-font-size;
					}
				}

				h3 {
					font-size: $font-size-base * 1.25;

					@include media-breakpoint-up(sm) {
						font-size: $font-size-base * 1.625;
					}
				}

				h2,
				h3 {
					text-align: center;
					font-weight: 400;
				}

				.slider {
					margin-top: 45px;

					.owl-dots {
						display: none;
					}

					.owl-nav {
						position: static;

						.owl-prev,
						.owl-next {
							color: $secondary;
							margin-top: -15px;

							i {
								font-size: $font-size-base * 1.25;
								font-weight: $font-weight-base;
							}
						}

						.owl-prev {
							left: 40%;

							.fa-chevron-left {
								&::before {
									content: "\f137" !important;
								}
							}
						}

						.owl-next {
							right: 45%;

							.fa-chevron-right {
								&::before {
									content: "\f138" !important;
								}
							}
						}
					}
				}
			}
		}

		.info {
			margin-top: 35px;

			.container-holder {
				max-width: 730px;
				margin: auto;
				text-align: center;

				.btn {
					@extend .btn-primary;
				}
			}
		}
	}

	.impression {
		overflow: hidden;

		.container-default {
			.container-holder {
				.inspiration {
					.card-large {
						@include make-col(12);
						@include media-breakpoint-up(lg) {
							@include make-col(6);
						}

						.card {
							.card-img-overlay {
								.card-caption {
									.card-title {
										bottom: 20px !important;
									}
								}
							}
						}

						&:first-of-type {
							.card {
								background-color: $blue;
								padding-bottom: 40px;
								@include media-breakpoint-up(sm) {
									padding-bottom: 0;
								}

								.card-img-overlay {
									background-color: $blue;
									padding: 50px 70px;

									.card-caption {
										.card-title-link {
											.card-title {
												@include media-breakpoint-up(lg) {
													font-size: $font-size-base * 1.875;
												}
											}
										}

										.card-description {
											max-width: 400px;
											font-size: $h4-font-size;
											margin: 15px 0 40px;
											display: block !important;

											&-content {
												color: $base-1;
												display: -webkit-box;
												-webkit-line-clamp: 3;
												-webkit-box-orient: vertical;
												overflow: hidden;
												text-overflow: ellipsis;
											}
										}
									}
								}
							}
						}
					}

					.item {
						padding: 0 !important;
						margin: 0;

						.card,
						.card-img-overlay {
							border-radius: 0;
						}

						&.card-small {
							@include media-breakpoint-up(lg) {
								@include make-col(4);
							}
						}

						&:not(:first-of-type) {
							.card {
								.card-img-overlay {
									.card-caption {
										.card-title {
											position: absolute;
											bottom: 50px;
											right: 30px;
											font-size: $font-size-base * 2.25;
											max-width: 292px;
											text-align: right;
											@include media-breakpoint-up(sm) {
												font-size: $font-size-base * 3.125;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.facilities {
		padding: 70px 0 0;

		.container-default {
			.container-holder {
				h2 {
					font-family: $font-great-vibes;
					font-size: $font-size-base * 2.25;
					@include media-breakpoint-up(sm) {
						font-size: $h1-font-size;
					}
				}

				h3 {
					font-size: $font-size-base * 1.25;

					@include media-breakpoint-up(sm) {
						font-size: $h3-font-size;
					}
				}

				h2,
				h3 {
					font-weight: $font-weight-base;
					text-align: center;
				}

				.btn {
					@extend .btn-secondary;
					display: flex !important;
					margin: 10px 30px 0;
					max-width: max-content;
					@include media-breakpoint-up(sm) {
						margin: 40px auto 0;
					}
				}
			}
		}

		.container-three-columns {
			margin-top: 50px;

			.container-holder {
				h3 {
					font-size: $h4-font-size;
				}

				ul {
					padding-left: 20px;
					list-style: none;

					li {
						position: relative;

						&::before {
							content: "\f111";
							position: absolute;
							left: -20px;
							top: 5px;
							font-family: $font-awesome;
							font-size: $font-size-base / 2;
						}
					}
				}
			}
		}
	}

	.two-column {
		padding: 0 !important;

		.container-default {
			.container-holder {
				max-width: 730px;
				margin: auto;
				padding: 130px 0 60px;
				text-align: center;

				h1 {
					font-family: $font-great-vibes;
					font-weight: $font-weight-base;
				}

				p {
					font-size: $font-size-base * 1.5;
				}
			}
		}
	}

	.content.info {
		background-color: $base-2;
		padding: 85px 0;

		.container-two-columns {
			.container-holder {
				.column {
					h2 {
						font-size: $font-size-base * 1.5;
					}

					h3 {
						font-size: $h4-font-size;
					}

					ul {
						list-style-type: none;
						padding-left: 20px;

						li {
							position: relative;
							margin-bottom: 10px;
							padding-left: 6px;

							&::before {
								content: "\f00c";
								position: absolute;
								left: -20px;
								top: 5px;
								font-family: $font-awesome;
								font-size: $font-size-base_sm;
								font-weight: $font-weight-bold;
							}
						}
					}
				}
			}
		}
	}

	.inspiration {
		margin-left: 0;
		margin-right: 0;
	}
}
